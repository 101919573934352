<template>
  <div>
    <div v-if="!isLoading">
      <div class="uk-flex uk-flex-between uk-text-large">
        <div>
          <div>Estado: En proceso</div>
        </div>
        <div class="uk-text-right">
          <div>
            Código de compra:
            <span class="uk-text-bold" test="redeem-code-display">{{
              redeem.code
            }}</span>
          </div>
          <div class="uk-text-default">
            {{ formatDatetime(redeem.created_at) }}
          </div>
        </div>
      </div>
      <table
        class="
          uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s
        "
      >
        <thead>
          <th width="10%">Destino</th>
          <th width="25%">Dirección</th>
          <th width="25%">Guía de envío</th>
          <th width="40%">Contenido</th>
        </thead>
        <tbody>
          <tr
            v-for="(shippingLabel, index) in redeem.shipping_labels"
            :key="shippingLabel.id"
          >
            <td class="uk-text-bold uk-text-large">#{{ index + 1 }}</td>
            <td>
              <div class="uk-text-bold">
                {{ shippingLabel.address.first_name }}
                {{ shippingLabel.address.last_name }}
              </div>
              <div>
                {{ shippingLabel.address.address }}, Zona
                {{ shippingLabel.address.zone }}
              </div>
              <div>
                {{ shippingLabel.address.city.name }},
                {{ shippingLabel.address.city.state.name }},
                {{ shippingLabel.address.city.state.country.spanish }}
              </div>
            </td>
            <td>
              <div class="uk-badge uk-text-default uk-padding-small">
                {{
                  shippingLabelStatus(
                    shippingLabel.last_shipping_label_checkpoint
                      ? shippingLabel.last_shipping_label_checkpoint.status
                      : "REQUESTED"
                  )
                }}
              </div>
              <div class="uk-margin-small-top">
                Número de rastreo:
                {{
                  shippingLabel.tracking_number
                    ? shippingLabel.tracking_number
                    : "Pendiente"
                }}
              </div>
              <div v-if="shippingLabel.estimated_delivery_date">
                Fecha estimada de entrega:
                {{ formatDate(shippingLabel.estimated_delivery_date) }}
              </div>
              <div>Proveedor: {{ shippingLabel.shipping_provider.name }}</div>
            </td>
            <td>
              <table
                class="
                  uk-table
                  uk-table-divider
                  uk-table-hover
                  uk-table-middle
                  uk-padding-remove
                "
              >
                <tr
                  v-for="redeemItem in redeem.redeem_items.filter(
                    (redeemItem) =>
                      redeemItem.shipping_labels[0].id === shippingLabel.id
                  )"
                  :key="'shipping-label-redeem-item-' + redeemItem.id"
                  class="uk-margin-small-bottom"
                >
                  <td width="15%">
                    <router-link
                      :to="
                        '/store/' +
                        (redeemItem.storefront_redeemable.redeemable_type ===
                        'App\\Models\\Product'
                          ? redeemItem.storefront_redeemable.redeemable_id
                          : redeemItem.storefront_redeemable.redeemable_type ===
                            'App\\Models\\ProductVariation'
                          ? redeemItem.storefront_redeemable.redeemable.product
                              .id
                          : '')
                      "
                    >
                      <img
                        width="80px"
                        v-if="redeemItem.media.length"
                        :src="redeemItem.media[0].url"
                        alt=""
                      />
                    </router-link>
                  </td>
                  <td width="5%">{{ redeemItem.quantity }}x</td>
                  <td width="40%">
                    {{
                      redeemItem.storefront_redeemable.redeemable_type ===
                      "App\\Models\\Product"
                        ? redeemItem.storefront_redeemable.display_name
                        : redeemItem.storefront_redeemable.redeemable.product
                            .storefront_redeemables[0].display_name +
                          " - " +
                          redeemItem.storefront_redeemable.redeemable.value
                    }}
                  </td>
                  <td width="40%" class="uk-text-right uk-padding-remove">
                    <img
                      v-if="pointsIcon"
                      :src="pointsIcon"
                      alt=""
                      width="50px"
                    />
                    {{
                      formatThousands(
                        redeemItem.quantity * redeemItem.point_price
                      )
                    }}
                    {{ pointsName }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="uk-text-right">Total de la compra</td>
            <td class="uk-text-right">
              <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
              {{
                formatThousands(
                  redeem.redeem_items.reduce(
                    (accum, redeemItem) =>
                      accum + redeemItem.point_price * redeemItem.quantity,
                    0
                  )
                )
              }}
              {{ pointsName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="uk-text-center">
      <span uk-spinner></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShowRedeem",

  data() {
    return {
      isLoading: true,
      redeem: null,
    };
  },

  computed: {
    ...mapGetters(["pointsName", "pointsIcon"]),
  },

  mounted() {
    this.getQuoteInfo();
  },

  methods: {
    getQuoteInfo() {
      this.isLoading = true;
      this.axios
        .get("/quotes/past-redeem/" + this.$route.params.id)
        .then(({ data: { redeem } }) => {
          this.redeem = redeem;

          this.redeem.redeem_items = this.redeem.redeem_items.map((item) => {
            item.media = item.storefront_redeemable.media;

            if (
              item.storefront_redeemable.redeemable_type ===
              "App\\Models\\ProductVariation"
            ) {
              item.media = [
                ...item.media,
                ...item.storefront_redeemable.redeemable.product
                  .storefront_redeemables[
                  item.storefront_redeemable.redeemable.product
                    .storefront_redeemables.length - 1
                ].media,
              ];
            }
            return item;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>